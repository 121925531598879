import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import Link from "gatsby-link";
import {checkData} from "../../helper";
import HtmlParser from "react-html-parser";

function Contentsection({data}) {
    return (
        <div className="tekrevol_contentsection p-100">
            <Container>
                <Row>
                    <Col xs={12} md={6} lg={5} xl={5} xxl={5}>
                        <div className="example" data-text={data?.content_title_body_txt}>
                            <h2>{checkData(data, 'content_title')}</h2>
                        </div>
                    </Col>
                    <Col xs={12} md={6} lg={6} xl={6} xxl={6} className="offset-lg-1">
                        <div>{HtmlParser(checkData(data, 'content_description'))}</div>
                        <Link to={checkData(data, 'content_btn_url')}
                              className="border_btn">{checkData(data, 'content_btn_txt')}</Link>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Contentsection;